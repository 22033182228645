import React from 'react';

function App() {
    const token = '83bccba5c9cf85c4e9023e74ca6274383151a7e5';
    const faqs = [
        {
            question: "What kind of internships does Elf Academy offer?",
            answer: `Elf Academy offers a diverse range of internships in various IT sectors, including development, DevOps, and software testing. Our internships provide hands-on experience and mentorship from industry professionals, ensuring you gain practical skills to kickstart your career.`
        },
        {
            question: "How long do Elf Academy internships last?",
            answer: `The duration of Elf Academy internships varies based on the program and industry partner requirements, typically lasting a few months. Exceptional interns who demonstrate rapid progress may complete their internships in a shorter period upon discussion with their mentor.`
        },
        {
            question: "How do I apply for an internship at Elf Academy?",
            answer: `To apply for an internship, sign up on our dashboard and browse through the available opportunities. Submit your application online, and our team will review it and contact you to discuss the next steps.`
        },
        {
            question: "What topics and skills are covered in the training?",
            answer: `Our training covers both theoretical and practical aspects of your chosen program, including the latest industry tools and technologies. You'll work on projects that simulate real-world software development tasks, providing you with valuable experience.`
        },
        {
            question: "Will I have access to mentors during the program?",
            answer: `Yes, you will have continuous access to mentors throughout your internship for guidance and support. They are available to help you with any challenges and provide valuable feedback on your progress.`
        },
        {
            question: "Are there any financial aid options available?",
            answer: `While we do not offer financial aid, we provide partial payment options to make our programs more accessible. This allows you to manage the financial commitment more easily while benefiting from our training.`
        },
        {
            question: "Are there any eligibility criteria for applying to the programs?",
            answer: `The only eligibility criterion is a genuine interest in learning programming and advancing your skills. We welcome applicants from all backgrounds who are eager to learn and grow in the IT field.`
        },
        {
            question: "Will I receive a certificate upon completion of the program?",
            answer: `Yes, upon successful completion of the program, you will receive a course completion certificate. Additionally, you will be provided with an experience letter from Elves On The Cloud for the duration of your internship.`
        },
        {
            question: "Does Elf Academy offer placement assistance after completing the program?",
            answer: `We offer comprehensive placement guidance and training on how to successfully pass interviews. However, securing a job ultimately depends on the candidate's performance during the interview process.`
        },
        {
            question: "Can I participate in the programs online, or are they conducted offline?",
            answer: `Following the COVID-19 pandemic, all our programs have transitioned to a fully remote setup. This format has been well-received by our trainees and allows for flexible learning from anywhere.`
        },
        {
            question: "What is Elf Academy's policy on refunds or cancellations?",
            answer: `We offer a 100% refund if you request it before the course begins. This policy ensures that you have the flexibility to make decisions that best suit your needs without financial risk.`
        },
        {
            question: "What kind of support can I expect during the program?",
            answer: `Throughout the program, you will receive continuous support from our instructors and mentors. You will have access to a wealth of resources and regular feedback to help you succeed.`
        }
    ];
    const workshopTopics = [
        {
            'id': 'django',
            'val': 'Full stack web development using Django',
        },
        {
            'id': 'python',
            'val': 'Programming using python',
        },
        {
            'id': 'react',
            'val': 'Front end development using React JS',
        },
        {
            'id': 'drf',
            'val': 'REST API development using django rest framework',
        },
        {
            'id': 'other',
            'val': 'Other or Combined (please specify below)',
        },
    ]
    let serverUrl = 'https://server.elvesonthecloud.com/api/elf-academy/';
    function workshopEnquiry(event) {
        document.getElementById('enquiry-spinner').style.display = '';
        event.preventDefault();
        let data = {
            'name': document.getElementById('name').value,
            'email': document.getElementById('email').value,
            'contact_no': document.getElementById('contact_no').value,
            'message': document.getElementById('message').value,
            'institute': document.getElementById('intstitute').value,
            'participants': document.getElementById('participants').value,
            'topic': document.getElementById('topic').value
        }
        fetch(serverUrl + 'workshop-enquiry/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
        }).then(res => res.json()).then(res => {
            if (res.data) {
                alert(res.message);
                document.getElementById('enquiry-spinner').style.display = 'none';
            }
        });
    }

    let [items, setItems] = React.useState([]);
    React.useEffect(() => {
        fetch(serverUrl + 'pricing', {
            method: 'GET',
            headers: {
                'Authorization': `Token ${token}`
            }
        }).then(res => res.json()).then(res => {
            setItems(res.data);
        })
    }, [])
    return (
        <React.Fragment>
            <div className="max-w-screen-xl px-4 py-8 mx-auto lg:py-24 lg:px-6" id="price">
                <div className="max-w-screen-md mx-auto mb-8 text-center lg:mb-12">
                    <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">Exclusive
                        Internship Opportunities</h2>
                    <p className="mb-5 font-light text-gray-500 sm:text-xl">Secure your spot in highly sought-after
                        internship positions. Only pay after you're selected for the program. Gain hands-on experience
                        and mentorship from experts at leading companies. Invest in your future with confidence!</p>
                </div>
                <div className="space-y-8 lg:grid lg:grid-cols-3 sm:gap-6 xl:gap-10 lg:space-y-0">
                    {items.map(item => {
                        return <div key={item.programme.id}
                            className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h3 className="mb-4 text-2xl font-semibold">{item.programme.name}</h3>
                            <p className="font-light text-gray-500 sm:text-lg">{item.programme.description.split('.')[0]}</p>
                            <div className="flex items-baseline justify-center my-8">
                                <span
                                    className="mr-2 text-5xl font-extrabold">{item.price.get_price.split('.')[0]}</span>
                                <span className="text-gray-500"> one time fee</span>
                            </div>
                            <ul className="mb-8 space-y-4 text-left">
                                <li className="flex items-center space-x-3">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <span><span className="font-semibold">Individual</span> training</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <span><span className="font-semibold">Interview</span> tips and mock rounds</span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <span>Industry expert <span className="font-semibold">Mentor</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <span>Real life <span className="font-semibold">project experience</span></span>
                                </li>
                                <li className="flex items-center space-x-3">
                                    <svg className="flex-shrink-0 w-5 h-5 text-green-500 dark:text-green-400"
                                        fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path fillRule="evenodd"
                                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                                            clipRule="evenodd"></path>
                                    </svg>
                                    <span>Free <span className="font-semibold">professional resume</span></span>
                                </li>
                            </ul>
                            <a href="https://dashboard.academy.elvesonthecloud.com"
                                className="text-white bg-purple-600 hover:bg-purple-700 focus:ring-4 focus:ring-purple-200 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:text-white  dark:focus:ring-purple-900">Get
                                started</a>
                        </div>
                    })}

                </div>
            </div>
            <section className="bg-gray-50 dark:bg-gray-800" id="workshop">
                <br />
                <br />
                <div className="max-w-screen-xl items-center gap-8 lg:grid lg:grid-cols-2 xl:gap-16">
                    <div className="text-gray-500 sm:text-lg dark:text-gray-400">
                        <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                            Empower Your Students with Industry-Standard Tools and Technologies through Our Workshop
                        </h2>
                        <p className="mb-8 font-light lg:text-xl">
                            We offer specialized workshops designed to equip your students with the practical
                            skills needed to succeed in today’s tech-driven world.
                            Our hands-on sessions cover a range of industry-standard tools and technologies,
                            ensuring that your students gain valuable, real-world experience.
                            Partner with us to bring these essential skills to your campus, preparing your students
                            for a successful career in the IT industry.
                        </p>
                        <ul role="list" className="pt-8 space-y-5 border-t border-gray-200 my-7 dark:border-gray-700">
                            <li className="flex space-x-3">
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"></path>
                                </svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    Career-Focused</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"></path>
                                </svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    Hands-On practice</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"></path>
                                </svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    Expert-Led</span>
                            </li>
                            <li className="flex space-x-3">
                                <svg className="flex-shrink-0 w-5 h-5 text-purple-500 dark:text-purple-400"
                                    fill="currentColor"
                                    viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd"
                                        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                                        clipRule="evenodd"></path>
                                </svg>
                                <span className="text-base font-medium leading-tight text-gray-900 dark:text-white">
                                    Interactive</span>
                            </li>
                        </ul>
                        <p className="mb-8 font-light lg:text-xl">
                            Please fill the form to submit an enquiry and we will call you back soon!
                        </p>
                    </div>
                    <form method="post" onSubmit={(event) => workshopEnquiry(event)}>
                        <div
                            className="flex flex-col max-w-lg p-6 mx-auto text-center text-gray-900 bg-white border border-gray-100 rounded-lg shadow dark:border-gray-600 xl:p-8 dark:bg-gray-800 dark:text-white">
                            <h2 className="mb-4 text-3xl font-extrabold tracking-tight text-gray-900 dark:text-white">
                                Workshop
                                Inquiry Form</h2>
                            <br />
                            <div className={"container mt-4"}>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="name"
                                        placeholder="Enter your name" required />
                                </div>
                                <div className="form-group">
                                    <input type="email" className="form-control" id="email"
                                        placeholder="Enter your email" required />
                                </div>
                                <div className="form-group">
                                    <input type="tel" className="form-control" id="contact_no"
                                        placeholder="Enter your contact number" required />
                                </div>
                                <div className="form-group">
                                    <input type="text" className="form-control" id="intstitute"
                                        placeholder="Enter the intstitute name" required />
                                </div>
                                <div className="form-group">
                                    <input type="number" className="form-control" id="participants"
                                        placeholder="Number of participants" required />
                                </div>
                                <div className="form-group">
                                    <select className="form-control" id="topic" required>
                                        <option value="">Select a workshop, interested in</option>
                                        {workshopTopics.map(item => {
                                            return <option value={item.id}>{item.val}</option>
                                        })}
                                    </select>
                                </div>
                                <div className="form-group">
                                    <textarea className="form-control" id="message" rows="5"
                                        placeholder="Provide any additional details or requests"></textarea>
                                </div>
                                <br />
                                <button type="submit"
                                    className="text-white bg-purple-700 hover:bg-purple-800 focus:ring-4 focus:ring-purple-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 sm:mr-2 lg:mr-0 dark:bg-purple-600 dark:hover:bg-purple-700 focus:outline-none dark:focus:ring-purple-800">
                                    <center>
                                        <div className="spinner" id={"enquiry-spinner"} style={{ display: 'none' }}>
                                        </div>
                                    </center>
                                    Submit Inquiry
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </section>
            <br />
            <br />
            <div className="max-w-screen-xl px-4 pb-8 mx-auto lg:pb-24 lg:px-6" id="faq">
                <h2
                    className="mb-6 text-3xl font-extrabold tracking-tight text-center text-gray-900 lg:mb-8 lg:text-3xl dark:text-white">
                    Frequently asked questions</h2>
                <div className="max-w-screen-md mx-auto">
                    <div id="accordion-flush" data-accordion="collapse"
                        data-active-classes="bg-white dark:bg-gray-900 text-gray-900 dark:text-white"
                        data-inactive-classes="text-gray-500 dark:text-gray-400">

                        {faqs.map((faq, id) => {
                            return <React.Fragment key={id}>
                                <h3 id={"accordion-flush-heading-" + id}>
                                    <button type="button" onClick={() => {
                                        document.getElementById("accordion-flush-body-" + id).classList.toggle('hidden')
                                    }}
                                        className="flex items-center justify-between w-full py-5 font-medium text-left text-gray-900 bg-white border-b border-gray-200 dark:border-gray-700 dark:bg-gray-900 dark:text-white"
                                        data-accordion-target={"#accordion-flush-body-" + id} aria-expanded="true"
                                        aria-controls={"accordion-flush-body-" + id}>
                                        <span>{faq.question}</span>
                                        <svg data-accordion-icon="" className="w-6 h-6 shrink-0" fill="currentColor"
                                            viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd"
                                                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                                                clipRule="evenodd"></path>
                                        </svg>
                                    </button>
                                </h3>
                                <div id={"accordion-flush-body-" + id} className="hidden"
                                    aria-labelledby={"accordion-flush-heading-" + id}>
                                    <div className="py-5 border-b border-gray-200 dark:border-gray-700 text-left"><p>
                                        {faq.answer}
                                    </p></div>
                                </div>
                            </React.Fragment>
                        })}
                    </div>

                </div>
            </div>
        </React.Fragment>
    );
}

export default App;
